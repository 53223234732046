export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"捷克Czech(cs)",
	"Alert_PageOneContent":"Budete mít k dispozici celkem 2 profily pro různé scénáře, jako je schůzka nebo jiná časová období dne pro optimální zážitky.",
	"Alert_PageTwoContent1":"Klepnutím upravte název profilu",
	"Alert_PageTwoContent2":"Klepnutím na ikony pod „Opakovat“ nastavíte dny účinnosti tohoto profilu, dalším klepnutím výběr zrušíte.",
	"Alert_PageThreeContent1":"„Celý den“ znamená, že vaše nastavení bude platit po celý den.",
	"Alert_PageThreeContent2":"Jakmile jsou upozornění vypnuta, lze upravit podle denního a nočního času",
	"Alert_PageThreeContent3":"Klepnutím na šipku \">\" změníte dobu výstrahy pro denní a noční dobu.",
	"Alert_PageFourContent1":"Urgentní upozornění na nízkou hladinu můžete vypnout pomocí přepínačů.",
	"Alert_PageFourContent2":"Úroveň výstrahy nelze změnit. Aplikace vás upozorní, když budou hodnoty glukózy nižší než nastavení.",
	"Alert_PageFourContent3":"Když probíhá relativně dlouhé období Urgent Low, aplikace vás upozorní v nastavených intervalech.",
	"Alert_PageFourContent4":"Zvuky upozornění si můžete přizpůsobit pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageFiveContent1":"Low Alert můžete vypnout přepínačem.",
	"Alert_PageFiveContent2":"Úroveň výstrahy lze změnit; aplikace vás upozorní, když budou hodnoty glukózy nižší než nastavení.",
	"Alert_PageFiveContent3":"Můžete nastavit interval upozornění. Když probíhá relativně dlouhé období nízké události, aplikace vás bude v nastavených intervalech informovat.",
	"Alert_PageFiveContent4":"Zvuky upozornění si můžete přizpůsobit pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageSixContent1":"High Alert můžete vypnout přepínačem.",
	"Alert_PageSixContent2":"Úroveň výstrahy lze změnit; aplikace vás upozorní, když jsou hodnoty glukózy vyšší než nastavení.",
	"Alert_PageSixContent3":"Můžete nastavit interval pro upozornění. Když probíhá relativně dlouhá doba vysoká událost, aplikace vás v nastavených intervalech upozorní.",
	"Alert_PageSixContent4":"Svůj první High Alert můžete ručně odložit o určitou dobu. Pokud vaše hladina glukózy zůstává v rozmezí vysoké výstrahy; upozornění budou pokračovat. Například aplikace spustí upozornění 20 minut po první vysoké události.",
	"Alert_PageSixContent5":"Zvuky výstrah si můžete přizpůsobit také pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageSevenContent1":"Klepnutím na „Přidat profil upozornění“ vytvoříte nové nastavení, které bude platit v různých obdobích. Všimněte si, že pokud vyberete překrývající se dny, které jsou zahrnuty v jiném profilu, nové nastavení přepíše stávající nastavení pro příslušné dny.",
	"Alert_PageSevenContent2":"Vytvoří se nový profil, můžete upravit dobu platnosti 2 profilů.",
	"Alert_Setting_Model":"Zvuky upozornění",
	"Alert_Setting_DelayFirst":"Zpoždění prvního vysokého varování",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Přizpůsobte si upozornění na glukózu",
	"Alert_UrgentLowAlert":"Naléhavá nízká výstraha",
	"Alert_LowAlert":"Nízká výstraha",
	"Alert_HighAlert":"Vysoká pohotovost",
	"Monday_Abb":"Po",
	"Tuesday_Abb":"Út",
	"Wednesday_Abb":"St",
	"Thursday_Abb":"Čt",
	"Friday_Abb":"Pá",
	"Saturday_Abb":"So",
	"Sunday_Abb":"Ne",
	"Alert_Setting_AllDay":"Celý den",
	"Alert_Setting_UrgentLow":"Naléhavá malá upozornění",
	"Alert_Setting_Low":"Malé upozornění",
	"Alert_Setting_High":"Velké upozornění",
	"Alert_Setting_Daytime":"Ve dne(08:00-20:00)",
	"Alert_Setting_Nighttime":"V noci(20:00-08:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Úroveň",
	"Alert_Setting_Vibration":"Vibrace",
	"Alert_Setting_Add":"Přidat profil upozornění",
	"Alert_Setting_System":"Systém",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"Výchozí hodnota",
	"Alert_Setting_Default2":"Výchozí hodnota2",
	"Alert_Setting_Min_5":"5 minut",
	"Alert_Setting_Min_30":"30 minut",
	"Alert_Setting_Repeat":"Opakovat",
	"FAQ_FaqTitle1":"Co je aplikace Syai Link?",
	"FAQ_FaqTitle2":"Jaký je rozdíl mezi Syai Link a Syai Tag?",
	"FAQ_FaqTitle3":"Mám činit rozhodnutí o léčbě na základě aplikace Syai Link?",
	"FAQ_FaqTitle4":"Jak funguje aplikace Syai Link?",
	"FAQ_FaqTitle5":"Budou data Syai Link zpožděna? Jak dlouhé je zpoždění?",
	"FAQ_FaqTitle6":"Jaká upozornění budu dostávat?",
	"FAQ_FaqTitle7":"Kolik uživatelů značky Syai mohu sledovat pomocí aplikace Syai Link?",
	"FAQ_FaqTitle8":"Když navážu spojení s více sdíleli, čí informace zobrazí můj widget?",
	"FAQ_FaqTitle9":"Mohu zobrazit historická data sdílejícího?",
	"FAQ_FaqTitle10":"Co znamená „HI“ a „LO“?",
	"FAQ_FaqTitle11":"Co znamená modrá oblast v grafu trendu?",
	"FAQ_FaqTitle12":"Co má cílové rozmezí společného s upozorněními na hladinu cukru v krvi?",
	"FAQ_FaqTitle13":"Mohu se svým účtem Syai Link přistupovat k jiným aplikacím nebo službám?",
	"FAQ_FaqTitle14":"Jaké informace se zobrazí v centru zpráv?",
	"FAQ_FaqContent1":"Aplikace Syai Link je aplikace speciálně navržená pro příbuzné, přátele a další uživatele Syai Tag. Můžete vidět údaje o krevním cukru v reálném čase, křivku krevního cukru a sledovat stav uživatelů Syai Tag a přijímat upozornění v případě abnormálního krevního cukru a dalších mimořádných událostí.",
	"FAQ_FaqContent2":"Aplikace Syai Link je navržena tak, aby ji mohla používat rodina, přátelé a pečovatelé. Na svých chytrých telefonech mohli vidět údaje o glykémii a upozornění v reálném čase. \nAplikace Syai Tag je navržena tak, aby ji mohli používat lidé s diabetem k provádění rutinního monitorování glukózy pomocí chytrého telefonu a monitoru Syai Tag Monitor.",
	"FAQ_FaqContent3":"Aplikace Syai Tag je navržena tak, aby ji mohli používat lidé s diabetem k provádění rutinního monitorování glukózy pomocí chytrého telefonu a monitoru Syai Tag.\"",
	"FAQ_FaqContent4":"Údaje o glukóze a analýzy poskytované v aplikaci by neměly být používány jako přímé doporučení pro léčbu. Než učiníte jakékoli zásadní rozhodnutí o léčbě, proveďte prosím další vyšetření, abyste je znovu zkontrolovali, jako jsou testy z prstu, abyste zajistili optimální aplikaci léčby.",
	"FAQ_FaqContent5":"Když aplikace Syai Tag obdrží údaje o glykémii z monitoru, odešle údaje o glukóze na server. Aplikace Syai Tag umožňuje uživateli pozvat rodinu, přátele a pečovatele, aby sledovali stav glukózy sdílejícího. Tato rodina, přátelé a pečovatelé si mohou nainstalovat aplikaci Syai Link do svých telefonů, přijmout pozvání ke sdílení a poté si prohlížet údaje o glykémii a přijímat upozornění na glykémii.",
	"FAQ_FaqContent6":"Latence dat bude způsobena různými faktory, jako je zpoždění sítě, přerušená připojení a tak dále. Za relativně ideálních podmínek by zpoždění mělo být menší než několik sekund.",
	"FAQ_FaqContent7":"A. Když hladina glukózy sdílejícího zařízení překročí nastavený rozsah připomenutí, jako je vysoká, nízká a naléhavě nízká hladina glukózy.\nb. Když je hladina glukózy sdílecího zařízení mimo přednastavené rozsahy, tj. pod %n nebo nad %r.\nc. Když sdílející ukončil svou relaci.\nd. Když je smazán účet sdílejícího.\ne. Když sdílející přestal sdílet.\nf. Když existují nepřečtené zprávy.\ng. Když jsou nové pozvánky.",
	"FAQ_FaqContent8":"Účet Syai Link může sledovat až 10 uživatelů Syai Tag.\nÚčet Syai Tag může pozvat až 10 uživatelů Syai Link.",
	"FAQ_FaqContent9":"Kvůli omezení funkčnosti widgetu můžeme zobrazit pouze informace prvního sdílejícího ve vašem seznamu. K upřednostnění preferovaného sdíleče můžete použít funkci „Připnout navrch“ na stránce nastavení.",
	"FAQ_FaqContent10":"Sledovatel může zobrazit data v reálném čase a data za posledních 24 hodin, ale ne data dříve. Data navíc můžete vidět až po přijetí pozvánky, data před tímto datem prohlížet nelze.",
	"FAQ_FaqContent11":"HI znamená hodnotu glukózy vyšší než %r.\n\"LO\" znamená hodnotu glukózy nižší než %n.",
	"FAQ_FaqContent12":"Ti dva jsou nezávislí a navzájem se neovlivňují. Cílový rozsah se zobrazí pouze v grafu trendu, který nastavil sdílející v aplikaci Syai Tag, a nelze jej upravit v aplikaci Syai Link. \nUpozornění na glukózu je upozornění, které vám bude zasláno, když hladina glukózy uživatele překročí normální rozsah. Toto lze upravit na stránce Nastavení.",
	"FAQ_FaqContent13":"Částečně je váš účet Syai Link ve skutečnosti účtem Syai, můžete přistupovat ke všem aplikacím a službám pro neprofesionální použití, jako je aplikace Syai Tag.",
	"FAQ_FaqContent14":"Všechna oznámení, která obdržíte, se zobrazí v centru zpráv. V centru zpráv můžete také zkontrolovat následující úkoly:\na. Nezpracované informace o pozvánce\nb. Až bude k dispozici aktualizace nové verze.\nc. Upozornění na glukózu pro události High/Low/Urgent Low."
}